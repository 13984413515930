<template>
  <div class="zt-page-content">
    <div class="block" style="height: 100%">
      <div class="list" style="height: calc(100% - 60px); overflow-y: auto">
        <el-table :data="list" style="width: 100%">
          <el-table-column
            prop="orderName"
            label="订单名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="factoryName"
            label="工厂名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="productName"
            label="产品名称"
            align="center"
          ></el-table-column>
          <el-table-column prop="startDate" label="开始日期" align="center">
          </el-table-column>
          <el-table-column prop="endDate" label="结束日期" align="center">
          </el-table-column>
          <el-table-column prop="customer_id" label="客户" align="center">
          </el-table-column>
          <el-table-column
            prop="postate"
            label="状态"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.postate == 0">已提议</div>
              <div v-if="scope.row.postate == 1">已采用</div>
              <div v-if="scope.row.postate == 2">不采用</div>
              <div v-if="scope.row.postate == 3">协商</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="数量"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template slot-scope="scope">
              <el-button
                type="text"
                :key="scope.row.orderId"
                @click="openDialog(scope.row.id)"
                >修改状态</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="padding: 12px">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="queryOpt.pageIndex"
          :page-size="queryOpt.pageSize"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="popVisible"
      title="修改状态"
      class="state-dialog"
      center
    >
      <el-select v-model="state">
        <el-option
          v-for="item in stateOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <div slot="footer">
        <el-button @click="popVisible = false">取消</el-button>
        <el-button type="primary" @click="submitState">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apsProdPlanList, saveProdPlanState } from "@/service/aps";
export default {
  watch: {
    queryOpt: {
      handler() {
        this.refreshItems();
      },
      deep: true,
    },
  },
  data() {
    return {
      state: 1,
      stateOptions: [
        {
          label: "已提议",
          value: 0,
        },
        {
          label: "已采用",
          value: 1,
        },
        {
          label: "不采用",
          value: 2,
        },
        {
          label: "协商",
          value: 3,
        },
      ],
      popVisible: false,
      total: 0,
      list: [],
      queryOpt: {
        ScreenName: "打样中心",
        pageIndex: 1,
        pageSize: 15,
      },
    };
  },

  created() {
    this.refreshItems();
  },
  methods: {
    submitState() {
      saveProdPlanState(this.id, this.state).then((rst) => {
        this.$message.success("保存成功");
        this.refreshItems();
        this.state = 1;
        this.popVisible = false;
      });
    },
    openDialog(id) {
      this.id = id;
      this.popVisible = true;
    },
    refreshItems() {
      this.list = [];
      // const type = this.$store.state.loginUser.comp.comp_type;
      // console.log(this.$store.state.loginUser.comp)
      this.queryOpt.ScreenName = this.$store.state.loginUser.comp.name;
      apsProdPlanList(this.queryOpt)
        .then((rst) => {
          // console.log(rst);
          if (rst && rst.Data) {
            this.list = rst.Data;
            this.total = rst.Total;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
  },
};
</script>
<style scoped>
div >>> .el-table .cell {
  text-align: center;
}
div >>> .el-dialog {
  width: 150px;
}
div >>> .el-dialog__body {
  background: #f5f5f5;
  text-align: center;
}

.zt-page-content {
  padding: 20px 20px 0 20px;
}
</style>